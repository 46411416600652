/**
 * QA environment setup.
 */
export const environment = {
production: false,
// apiURL: "https://ihm-api-dev.varuna-sentinels.com",
apiURL: "https://fgpa9a0bei.execute-api.eu-west-1.amazonaws.com/qa",
apiKey: "McJmCx2GBw43WEzd00B9l6IIJDAHmAEH1lhmoWRm",
region: "eu-west-1",
bucketName: "vsmps-assets-dev",
homeUrl: 'https://dev.varuna-sentinels.com/login.html',
assetsLink: "https://mps-cdn-dev.s3.eu-west-1.amazonaws.com/assets/",
// jasperServerUrl: "http://34.242.193.177:8080/jasperserver/flow.html?_flowId=viewReportFlow&j_username=ihm&j_password=ihm"
jasperServerUrl: "https://jasperihm.varuna-sentinels.com:8443/jasperserver/flow.html?_flowId=viewReportFlow",
jasperEnvPrefix: "DEV",
shipPoSampleFile: "https://ihm-assets-dev.s3-eu-west-1.amazonaws.com/Sample+Format/Sample_Format_Of_Supplier_Ship_PO.xlsx",
publicKey: "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2dRXI+y8T+ggRQf/G7zL//udwZfQrH2HsMeuSG9WYxQjZdu6EnzZ/XkCsPLVzhir2PyVL5ZXWhOwYk2eyBZC3BCFtxOnRUNpE0jLqVWF6eicsgPVKPuTYMjdy0XZ3ZQAJNNSlFP0ta2bBuJKKdy2Y8ztfdlWGajwrdpUcDasGVx9YzBqFKlK+DxqR1rFFHwnCFDWvdiX1g8iS3GEgCqpjpXhrW+WBu7C+pkeDMUSDb53hTjXH8l804RKP4siU9oytsoyc7BO8lnIluYUkZsxxQwuYk34mRuTII5CbMhACPkC13kCvC/S6Hqakt8YCE802EPbVO0SmTGL6buik5LxYQIDAQAB\n-----END PUBLIC KEY-----",
privateKey: "-----BEGIN RSA PRIVATE KEY-----\nMIIEpAIBAAKCAQEA2dRXI+y8T+ggRQf/G7zL//udwZfQrH2HsMeuSG9WYxQjZdu6EnzZ/XkCsPLVzhir2PyVL5ZXWhOwYk2eyBZC3BCFtxOnRUNpE0jLqVWF6eicsgPVKPuTYMjdy0XZ3ZQAJNNSlFP0ta2bBuJKKdy2Y8ztfdlWGajwrdpUcDasGVx9YzBqFKlK+DxqR1rFFHwnCFDWvdiX1g8iS3GEgCqpjpXhrW+WBu7C+pkeDMUSDb53hTjXH8l804RKP4siU9oytsoyc7BO8lnIluYUkZsxxQwuYk34mRuTII5CbMhACPkC13kCvC/S6Hqakt8YCE802EPbVO0SmTGL6buik5LxYQIDAQABAoIBABGQEUaQO86lhJSMRIFUA46sFtgZCXYe2OXj86VbdLegVApOYP5Emr7ZFuJ1cc+gJZrlIpInqPtWQw5p+7deNB3SJzXPm9j1ym8Hm4QXpShq3Ndtf65COmu/RkKxaxN2b1yIvUvASSaszKcNb7VYWkxJpYxUDaEcAevCgrRZdB7HNCVmpxL7a9yjJBqdN5133MwdRDU7l3nE0dPAXeEF8QWQ31sFUiA3YCFkRgH1QLW3NnDuruWls2blP9JWWxZ2FaACE2lSq2k8oAYC85hPLO/yjzWkh4MangCRzZhfwEFzRswH4IByZF39sHyhSguSH/qmQlGDY3vVHg7VzNHwZsECgYEA97Jm6TzG3lTU8/Sa2Cx7Swnvj01LTz9YYaGKyN0HbwZy+A8gG6W59GdDgc1baPU7tR3fuB0pFw7qbH2INevXJdm1RvSsZs6gcK/Toj6Gvuc3WHbVvlYX70rheYzw3kbhM7pAGhFQaMVxsHdjtknuLct20ToJxnzBN7DceNxM4p0CgYEA4SGh8lOY1tVzcQF8kkZn2OTkp6IsKL2m4ZSr71ACYO00Tyu/DAmKDxI/vapsDPLrNLv7I9bM60MdtRTZYf+x2NNoFcWmE1wdqrfWGWjYHmpa+x5Bhaoz1zqmenoI9lbBK/lvVrnZLH4Eh++ZSkDLDcuUBH7+9hrb/G+4zwibfJUCgYB5D6HKtyC/g5i9TzzrEPqFl2qVkIKRTfWFai3ptFWwxuckJEheGubC9EGDTHoz10ACaoJwexgdI/YhZk1PhWAXKMu3zVgBu1WhHd6C+qpi3aEoRJR9PZjfJKsE/ISxLtVcsdgopXO2cWH6jK+YZZPaoAoHgqVKdKj+7hsjmxCc5QKBgQCDcLZzm9k1POQsizim0lJZMLJLKQTtOGUReVK04xqwam1K9/iP15XyXK44VyLEoKMwY7wp3YRAqyvSCH0umTHFrRAs/fuLfBGaFcD7jtn3UBMUYELS/l9L/uMpyMwovtgDi5s0oEcqkdylXP87ef7TbBNcWKUVHPNpO/VDcvwMzQKBgQDApmyO5JfGSLEG4TeDS0Yq8sA2pUnnVq6zln6IN1Iz5iRsGyL7k3fAqpzEA+8DmXAzckdDf0wWUN+1tLeHfFae0Wvw1Gb7A1eKlYCb4IEx2YkHh3loLZ87ZM7Skx9MSo1emqsh0Kqa28QHJ+vP+3kdbKQauaS6J5Y8Vq2SrR1kzA==\n-----END RSA PRIVATE KEY-----"
};